<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) You are given a
        <stemble-latex content="$3.00\,\text{M}$" />
        stock solution of
        <stemble-latex content="$\ce{H2SO4}.$" />
        You need to make
        <stemble-latex content="$25.00\,\text{mL}$" />
        of a
        <number-value :value="conc" unit="\text{M}" />
        solution of
        <stemble-latex content="$\ce{H2SO4}.$" />
        How many mL of the stock solution will you need to use?
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{V:}$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">b) Explain how you came to your answer in part a).</p>

      <p class="mb-n3">
        <s-textarea v-model="inputs.writtenAnswer" outlined :counter="true" :maxlength="1200" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemFSU101_Q2',
  components: {
    StembleLatex,
    ChemicalLatex,
    STextarea,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        writtenAnswer: null,
      },
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
  },
};
</script>
